<template>
  <v-row class="py-4 align-center justify-center" no-gutters>
    <v-progress-circular :size="48" color="primary" indeterminate />
  </v-row>
</template>

<script>
export default {};
</script>

<style>
</style>