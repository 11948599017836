<template>
  <div class="d-flex flex-column fill-height">
    <!-- appbar -->
    <v-app-bar color="altback" dense app elevate-on-scroll scroll-target="">
      <v-app-bar-nav-icon
        class="rounded-lg ml-1"
        small
        @click.stop="drawer = !drawer"
      />

      <v-spacer />

      <v-btn class="rounded-lg mr-3" icon small @click="changeTheme()">
        <v-icon v-if="$vuetify.theme.dark">mdi-moon-waning-crescent</v-icon>
        <v-icon v-else> mdi-white-balance-sunny </v-icon>
      </v-btn>

      <v-avatar size="32">
        <v-img :src="require('@/assets/utils/profile.png')" />
      </v-avatar>
    </v-app-bar>

    <!-- drawer -->
    <v-navigation-drawer
      v-model="drawer"
      color="altback"
      floating
      :mini-variant="$vuetify.breakpoint.lgAndUp"
      app
    >
      <template v-slot:prepend>
        <v-row class="align-center justify-center py-1" no-gutters>
          <!-- logo mini -->
          <v-avatar v-if="$vuetify.breakpoint.lgAndUp">
            <v-img :src="require('@/assets/logo-simple.png')" />
          </v-avatar>

          <!-- logo mobile -->
          <v-img
            v-else
            :src="
              require(`@/assets/logo-${
                $vuetify.theme.dark ? 'white' : 'black'
              }.png`)
            "
          />
        </v-row>
      </template>

      <!-- routes -->
      <v-container class="pa-0" fill-height fluid>
        <v-list width="100%" dense nav>
          <v-list-item
            v-for="(route, index) in routes"
            :key="index"
            :to="route.path"
            :disabled="route.disabled"
            class="my-4"
          >
            <v-list-item-icon>
              <v-icon>{{ route.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title v-text="route.label" />
          </v-list-item>
        </v-list>
      </v-container>

      <template v-slot:append>
        <v-list width="100%" dense nav>
          <v-list-item @click="exit()">
            <v-list-item-icon>
              <v-icon>mdi-login-variant</v-icon>
            </v-list-item-icon>

            <v-list-item-title> Sair </v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <!-- slot view -->
    <v-main class="altback">
      <v-sheet class="radius background pl-1 py-1" height="100%">
        <slot />
      </v-sheet>
    </v-main>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      drawer: true,
      routes: [
        { label: "Ínicio", path: "/main", icon: "mdi-home", disabled: false },
        {
          label: "Usuários",
          path: "/users",
          icon: "mdi-account-multiple",
          disabled: true,
        },
        {
          label: "Agendamentos",
          path: "/schedules",
          icon: "mdi-calendar",
          disabled: false,
        },
        {
          label: "Vouchers",
          path: "/vouchers",
          icon: "mdi-ticket-percent-outline",
          disabled: false,
        },
        {
          label: "Galeria",
          path: "/gallery",
          icon: "mdi-image-multiple",
          disabled: true,
        },
      ],
    };
  },

  methods: {
    ...mapActions(["setSignOut"]),

    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark", this.$vuetify.theme.dark);
    },

    exit() {
      this.setSignOut();
      this.$router.push("/auth");
    },
  },
};
</script>

<style lang="scss" scoped>
.body-color {
  background-color: #0e0e0f;
}

.radius {
  border-radius: 24px 24px 0px 0px;
}
</style>