<template>
  <v-app id="app">
    <SplashScreen v-if="loading" />

    <Application v-else-if="$store.getters.authenticated && handleTemplate()">
      <router-view />
    </Application>

    <v-main v-else class="background">
      <router-view />
    </v-main>

    <SnackBar />
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import { loadSession } from "./services/auth";
import { api } from "./services";
import Application from "./template/Application.vue";
import SplashScreen from "./template/SplashScreen";
import SnackBar from "./components/app/SnackBar";

export default {
  name: "App",

  data() {
    return {
      loading: true,
    };
  },

  components: {
    Application,
    SplashScreen,
    SnackBar,
  },

  created() {
    this.startSession();
  },

  beforeMount() {
    const dark = localStorage.getItem("dark-mode");
    this.$vuetify.theme.dark = dark == "true" || false;
  },

  methods: {
    ...mapActions(["setSignIn", "setSignOut"]),

    // checks if the token is still valid for a session
    async startSession() {
      const token = this.$store.getters.token;

      if (token) {
        api.defaults.headers.common["Authorization"] = token;

        try {
          await loadSession().then((res) => {
            this.setSignIn({
              token: token,
              user: res.body,
            });
          });
        } catch (err) {
          this.setSignOut();
          this.$router.push({ path: "/auth" });
        }
      }

      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },

    handleTemplate() {
      const pages = ["Sign In"];

      return !pages.includes(this.$route.name);
    },
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.zoom {
  transition: transform 0.2s;
}

.zoom:hover {
  transform: scale(1.05);
}

.pointer-hand {
  cursor: pointer;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.gradient {
  background: #ffb300;
  background: linear-gradient(30deg, #ffb300, #ff6700);
}
</style>
