<template>
  <v-main class="background">
    <v-container fill-height fluid>
      <v-row class="justify-center align-center">
        <v-col cols="5" sm="4" md="3" lg="2">
          <v-img
            :src="
              require(`@/assets/logo-${
                $vuetify.theme.dark ? 'white' : 'black'
              }.png`)
            "
            contain
          />
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {};
</script>

<style>
</style>
