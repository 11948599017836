import Vue from "vue";
import VueRouter from "vue-router";
import beforeEach from "./beforeEach";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/main",
  },
  {
    path: "/auth",
    name: "Auth",
    meta: { preventAuth: true },
    component: () => import("../views/auth/Auth.vue"),
  },
  {
    path: "/main",
    name: "Main",
    meta: { requiredAuth: true },
    component: () => import("../views/main/Main.vue"),
  },
  {
    path: "/users",
    name: "Users",
    meta: { requiredAuth: true },
    component: () => import("../views/users/Users.vue"),
  },
  {
    path: "/schedules",
    meta: { requiredAuth: true },
    component: () => import("../views/schedules/Schedules.vue"),
    children: [
      {
        path: "",
        name: "Schedules",
        meta: { requiredAuth: true },
        component: () => import("../views/schedules/children/Schedules.vue"),
      },
      {
        path: "manage",
        name: "Manage Schedules",
        meta: { requiredAuth: true },
        component: () => import("../views/schedules/children/Manage.vue"),
      }
    ]
  },
  {
    path: "/gallery",
    name: "Gallery",
    meta: { requiredAuth: true },
    component: () => import("../views/gallery/Gallery.vue"),
  },
  {
    path: "/vouchers",
    name: "Vouchers",
    meta: { requiredAuth: true },
    component: () => import("../views/vouchers/Vouchers.vue"),
  },
  {
    path: "*",
    name: "Not Found",
    component: () => import("../views/notFound/NotFound.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(beforeEach);

export default router;
